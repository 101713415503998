import { Injectable } from "@angular/core";
import { BehaviorSubject, PartialObserver } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class VideocallPipService {
    private callIdSubject = new BehaviorSubject<string | null>(null);

    constructor() {}

    setCallId(callId: string | null) {
        this.callIdSubject.next(callId);
    }

    callIdSubscribe(observer: PartialObserver<string | null>) {
        this.callIdSubject.subscribe(observer);
    }
}
