import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'account',
        title: 'Account',
        translate: 'NAV.ACCOUNT',
        type: 'group',
        children: [
            {
                id       : 'profile',
                title    : 'Profile',
                translate: 'NAV.PROFILE.TITLE',
                type     : 'item',
                icon     : 'person_outline',
                url      : '/profile'
            },
        ]
    }
];
