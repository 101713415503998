import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import {
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinner,
    MatProgressSpinnerModule,
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from "@angular/material";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { AuthGuard } from "./guards/auth.guard";
import { SessionInterceptor } from "./guards/interceptors/session.interceptor";

import { ColorPickerModule } from "ngx-color-picker";

// Import your library
import { InternationalPhoneNumberModule } from "ngx-international-phone-number";
import { ProgressSpinnerComponent } from "./main/progress-spinner/progress-spinner.component";
import { ConfirmStatusDialogComponent } from './main/building/owners/unit-detail/tabs/violations-table/confirm-status-dialog copy/confirm-status-dialog.component';
import { ResidentformComponent } from './main/residentform/residentform.component';
// Import Safe HTML Pipe
import { SafeHtmlReports } from "./main/reports/safeHtml.pipe";
import { RedirectedComponent } from "./main/smart-locks/redirected/redirected.component";

const appRoutes: Routes = [
    {
        path: "",
        children: [
            {
                path: "",
                redirectTo: "profile",
                pathMatch: "full",
            },
            {
                path: "*",
                redirectTo: "invitations",
            },
            {
                path: "invitations",
                loadChildren:
                    "./main/owner/invitations/invitations.module#InvitationsModule",
            },
            {
                path: "profile",
                loadChildren:
                    "./main/owner/profile/profile.module#ProfileModule",
            },
            {
                path: "security",
                loadChildren: "./main/security/security.module#SecurityModule",
            },
            {
                path: "building",
                loadChildren: "./main/building/building.module#BuildingModule",
            },
            {
                path: "buildings",
                loadChildren:
                    "./main/buildings/buildings.module#BuildingsModule",
            },
            {
                path: "users",
                loadChildren: "./main/users/users.module#UsersModule",
            },
            {
                path: "owner",
                loadChildren: "./main/owner/owner.module#OwnerModule",
            },
            {
                path: "connectors",
                loadChildren:
                    "./main/gtconnector/connectors.module#ConnectorsModule",
            },
            {
                path: "guest-type",
                loadChildren:
                    "./main/guest-type/guest-type.module#GuestTypeModule",
            },
            {
                path: "dealers",
                loadChildren: "./main/dealers/dealers.module#DealersModule",
            },
                {
                    path: "licenses",
                    loadChildren: "./main/licenses/licenses.module#LicensesModule",
                },
                {
                    path: "kiosks",
                    loadChildren: "./main/kiosks/kiosks.module#KiosksModule",
                },
                {
                    path: "speed-violation",
                    loadChildren:
                        "./main/speed-violation/speed-violation.module#SpeedViolationModule",
                },
            ],
            canActivate: [AuthGuard],
        },
        {
            path: "auth",
            loadChildren: "./main/auth/auth.module#AuthModule",
        },
        {
            path: 'invitation-request',
            loadChildren: './main/invitation-request/invitation-request.module#InvitationRequestModule'
    },
    {
        path: 'passview',
        loadChildren: './main/passview/passview.module#PassviewModule'
    },
    {
        path: 'residentform',
        loadChildren: './main/residentform/residentform.module#ResidentformModule'
    },
    {
        path: "smartlocks/settings/ks/redirect",
        component: RedirectedComponent,
    },
];

@NgModule({
    declarations: [
        AppComponent,
        ProgressSpinnerComponent,
        ConfirmStatusDialogComponent,
        RedirectedComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        ColorPickerModule,
        MatProgressSpinnerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        InternationalPhoneNumberModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptor,
            multi: true,
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { panelClass: ["custom-snackbar-theme"] },
        },
        SafeHtmlReports
    ],
    entryComponents: [ConfirmStatusDialogComponent],
})
export class AppModule { }