export const locale = {
    lang: "en",
    data: {
        VIDEOCALL_PIP: {
            INCOMING_CALL: "Incoming call",
            DOOR_OPENED: "Door opened!",
            INVALID_CALL: "Invalid Call",
            OPEN_DOOR_DOUBLE_CLICK: "You must double click to open the door",
            NO_PERMISSIONS: "No camera/microphone permissions were given",
            SESSION_ALREADY_EXISTS: "Session already exists",
        },
    },
};
