export const locale = {
    lang: "es",
    data: {
        VIDEOCALL_PIP: {
            INCOMING_CALL: "Llamada entrante",
            DOOR_OPENED: "¡Puerta abierta!",
            INVALID_CALL: "Llamada inválida",
            OPEN_DOOR_DOUBLE_CLICK: "Debes hacer doble click para abrir la puerta",
            NO_PERMISSIONS: "No se concedieron permisos de cámara/micrófono",
            SESSION_ALREADY_EXISTS: "La sesión ya existe",
        },
    },
};
