import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule, MatDialogModule, MatSelectModule, MatTooltipModule } from '@angular/material';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent, PhotoUserDialogComponent, LogoutDialogComponent, SafeHtml } from 'app/layout/components/toolbar/toolbar.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        ToolbarComponent,
        LogoutDialogComponent,
        PhotoUserDialogComponent,
        SafeHtml
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatDialogModule,
        MatTooltipModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MatSelectModule,
        TranslateModule
    ],
    exports     : [
        ToolbarComponent,
    ],
    entryComponents: [
        LogoutDialogComponent,
        PhotoUserDialogComponent
    ]
})
export class ToolbarModule
{
}
