import { Component, Inject, OnInit } from "@angular/core";
import {
    MatDialogRef,
    MatSnackBar,
    MAT_DIALOG_DATA,
} from "@angular/material";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { BuildingService } from "app/main/building/building.service";

@Component({
    selector: "app-confirm-status-dialog",
    templateUrl: "./confirm-status-dialog.component.html",
    styleUrls: ["./confirm-status-dialog.component.scss"],
})
export class ConfirmStatusDialogComponent implements OnInit {

    isLoading: boolean = false;
    inputType: string = 'password';
    newTicketStatus: any;
    buildingId: string;
    unitHouseId: string;
    unitNumber: number;
    comment: string;
    password: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private buildingService: BuildingService,
        private fuseProgressBar: FuseProgressBarService,
        private matDialogRef: MatDialogRef<ConfirmStatusDialogComponent>,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit(): void {
        // Close without updating on dialog's backdrop click
        this.matDialogRef.backdropClick().subscribe(() => {
            this.close(false)
        })

        this.newTicketStatus = this.data.newTicketStatus
        this.buildingId = this.data.buildingId
        this.unitHouseId = this.data.unitHouseId
        this.unitNumber = this.data.unitNumber
    }

    save(): void {
        this.isLoading = true
        this.fuseProgressBar.show()
        const body = {
            ...this.newTicketStatus,
            reason: this.comment,
            password: this.password,
        }
        this.buildingService.postSlchgstatus(this.buildingId, this.unitHouseId, body).subscribe(
            data => {
                this.isLoading = false
                this.fuseProgressBar.hide();
                this.snackBar.open(data.message, "Close", {
                    duration: 5000,
                    panelClass: ["snack-success"],
                });
                this.close(true)
            },
            error => {
                this.isLoading = false
                this.fuseProgressBar.hide();
                this.snackBar.open(error.error.message, "Close", {
                    duration: 5000,
                    panelClass: ["snack-error"],
                });
            }
        )
    }

    close(updated: boolean): void {
        this.matDialogRef.close(updated)
    }

    autoGrowTextZone(event: any) {
        event.target.style.height = "0px";
        event.target.style.height = (event.target.scrollHeight + 18)+"px";
    }

}
