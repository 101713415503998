import { NgModule, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'safeHtml'})
export class SafeHtmlReports {
  constructor(private sanitizer:DomSanitizer){}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }
}

@NgModule({
  declarations: [ SafeHtmlReports ],
  exports: [ SafeHtmlReports ]
})
export class SafeHtmlReportsModule {}