import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import { fuseConfig } from '../../fuse-config';
import * as faye from 'faye';

@Injectable({
    providedIn: 'root'
})
export class SecurityService {
    private subject;
    private scannerSubject;
    private monitorSubject;
    public selectedGate;
    public selectedBuilding;
    public selectedBuildingSettings;
    public selectedGateCameras;
    public selectedGateDoor;
    public hasScanner;
    public currentEvent;
    public saveShowLicenseNumber;
    public gate = null;
    public isCheckedIn: boolean;
    constructor(private http: HttpClient) {
      this.subject = new Subject();
      this.scannerSubject = new Subject();
      this.monitorSubject = new Subject();
    }

    monitorSubscribe(next, error?, complete?): any {
        return this.monitorSubject.subscribe(next, error, complete);
    }

    monitorNext(data): any {
        return this.monitorSubject.next(data);
    }

    monitorUnsubscribe(): any {
        return this.monitorSubject.unsubscribe();
    }

    
    scannerSubscribe(next, error?, complete?): any {
        return this.scannerSubject.subscribe(next, error, complete);
    }

    scannerUnsubscribe(): any {
        return this.scannerSubject.unsubscribe();
    }

    scan(data): any {
        this.scannerSubject.next(data);
    }

    subscribe(next, error?, complete?): any {
        return this.subject.subscribe(next, error, complete);
    }

    unsubscribe(): any {
        return this.subject.unsubscribe();
    }

    selectGate(gate): any {
        this.gate = gate;
        this.subject.next(gate);
    }

    getGates(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/security/gates', {headers: headers, withCredentials: true});
    }

    scanQR(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.post( fuseConfig.url + '/security/scan', body, {headers: headers, withCredentials: true});
    }

    getLastGuest(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/security/lastGuest', {headers: headers, withCredentials: true});
    }

    getTodayGuests(gate): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/security/todayGuests/'+gate, {headers: headers, withCredentials: true});
    }

    getBuildingUnits(building): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getBuildingUnits/' + building, {headers: headers, withCredentials: true});
    }

    getUnitHosts(building, unit): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getUnitHosts/' + building + '/' + unit, {headers: headers, withCredentials: true});
    }

    getUnitScans(unit): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getUnitScans/' + unit, {headers: headers, withCredentials: true});
    }

    getUnitNotifications(unit): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getUnitNotifications/' + unit, {headers: headers, withCredentials: true});
    }

    createInvitation(model): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        // console.log('model', model);
        const body = {... model};
        if (body.allDay === true) {
            delete body.fromHour;
            delete body.toHour;
        }
        body.fromDate = moment(body.fromDate).format('YYYY-MM-DD');
        body.toDate = moment(body.toDate).format('YYYY-MM-DD');
        return this.http.post(fuseConfig.url + '/security/quickInvitation', body, {headers: headers, withCredentials: true});
    }
    
    getOwnerGuests(id): Observable<any>{
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getOwnerGuests/' + id, {headers: headers, withCredentials: true});
    }

    searchOwners(owner, building): Observable<any>{
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getQuickList/' + building + `?ownername=${owner}`, {headers: headers, withCredentials: true});
    }

    searchPin(pin, building): Observable<any>{
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getQuickList/' + building + `?pin=${pin}`, {headers: headers, withCredentials: true});
    }

    searchUnits(unit, building): Observable<any>{
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getQuickList/' + building + `?address=${unit}`, {headers: headers, withCredentials: true});
    }

    searchGuests(guest, building): Observable<any>{
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getQuickList/' + building + `?guestname=${guest}&carplate=${guest}`, {headers: headers, withCredentials: true});
    }

    searchPhones(phone, building): Observable<any>{
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getQuickList/' + building + `?ownerphone=${phone}`, {headers: headers, withCredentials: true});
    }

    getUnitResidents(unit): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getUnitResidents/' + unit, {headers: headers, withCredentials: true});
    }

    getUnitGuests(unit, filterActive): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getUnitGuests/' + unit + `?filterActive=${filterActive}`, {headers: headers, withCredentials: true});
    }

    getUnitGuestsByName(unit, filterActive, name): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getUnitGuests/' + unit + `?filterActive=${filterActive}&filter=guestname:like:${name}`,
         {headers: headers, withCredentials: true});
    }

    getUnitGuestsByCompany(unit, filterActive, company): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getUnitGuests/' + unit + `?filterActive=${filterActive}&filter=companyName:like:${company}`,
         {headers: headers, withCredentials: true});
    }

    getUnitVehicles(unit): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getUnitVehicles/' + unit, {headers: headers, withCredentials: true});
    }

    getResidentVehicles(unit, resident): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getUnitVehicles/' + unit + `?filter=resident:eq:${resident}`, {headers: headers, withCredentials: true});
    }

    getResidentGuests(unit, resident, filterActive): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getUnitGuests/' + unit + `?filterActive=${filterActive}` + 
        `&filter=resident:eq:${resident}`, {headers: headers, withCredentials: true});
    }

    getOwnerDetails(unit): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getOwnerDetails/' + unit, {headers: headers, withCredentials: true});
    }

    sendNotification(unit, message): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        const body = {
            unitHouse: unit,
            message: message
        };
        return this.http.post(fuseConfig.url + '/security/postUnitNotification', body, {headers: headers, withCredentials: true});
    }

    getDoors(connector): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/security/goutru_connector/' + connector + '/door', {headers: headers, withCredentials: true});
    }

    getGateDoors(gate): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/gateDoors/' + gate, {headers: headers, withCredentials: true});
    }

    openDoor(door): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.post( fuseConfig.url + '/security/door/open', { door }, {headers: headers, withCredentials: true});
    }

    // getPhoto(gtc_port, id, channel): Observable<any> {
    //     const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
    //     const headers = new HttpHeaders().set('authorization', token);
    //     headers.append('Content-Type', 'application/json');
    //     return this.http.get( fuseConfig.url2 + `/device/${id}/photo/${channel}/${gtc_port}`, {headers: headers, withCredentials: true});
    // }
    
    getPhoto(device): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.post( fuseConfig.url + '/security/device/photo/', { device: device }, {headers: headers, withCredentials: true});
    }

    scanLicense(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.scannerUrl + '/scan/', {headers: headers});
    }

    getInvitationDetails(gate, id): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getInvitationDetails/' + gate + '/' + id, {headers: headers, withCredentials: true});
    }

    getGroupInvitationDetails(id): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getGroupInvitationDetails/' + id, {headers: headers, withCredentials: true});
    }

    getScanImage(camera, scanId, type): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/getScanImage/' + scanId + '/' + type + '/' + camera, {headers: headers, withCredentials: true});
    }

    scanGroupInvitation(data): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.post(fuseConfig.url + '/security/scanGroupInvitation/', data, {headers: headers, withCredentials: true});
    }

    scanResident(data): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.post(fuseConfig.url + '/security/scanResident/', data, {headers: headers, withCredentials: true});
    }
    
    getLogAccees(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/security/monitor/doorEvents', {headers: headers, withCredentials: true});
    }
    
    getLastAcceesEvent(building, gate): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        return this.http.get( fuseConfig.url + '/security/monitor/lastEventEntry/' + building + '/' + gate, {headers: headers, withCredentials: true});
    }
    
    getPhotoEvent(gtc_port, eventId): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        return this.http.get( fuseConfig.url + '/security/monitor/photoEvent/' + gtc_port + '/' + eventId, {headers: headers, withCredentials: true});
    }

    setupMonitorLiveWS(): any {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');

        const wsClient = new faye.Client(fuseConfig.fayeUrl + '/fayeWs', {
            retry: 30
        }); // WS URL DE SERVER : port / lib

        wsClient.addExtension({
            outgoing: (message: any, callback: any) => { // function(message : any, callback : any) {
                message.ext = message.ext || {};
                message.ext.token = token;
                callback(message);
            }
        });

        return wsClient;
    }

    getGuestTypes(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/shared/guest_type' , {headers: headers, withCredentials: true});
    }

    getGuestGroupTypes(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/shared/guest_type?filter=for_groups:eq:true' , {headers: headers, withCredentials: true});
    }

    getEvents(form, text): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        var headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        headers = headers.append('Login-Origin', 'web');
        // console.log(form);
        const filter = {... form};
        let search = '';
        let slice = false;
        if (filter.dateFrom !== '' || filter.dateTo !== '') {
            search = `?dateFrom=${moment(filter.dateFrom).format('YYYY-MM-DD')}&dateTo=${moment(filter.dateTo).format('YYYY-MM-DD')}`;
        }
        
        if (filter.status !== '') {
            search.length > 0 ? search += '&status=' : search = '?filter=';
            search += filter.status;
        }
        if (filter.type !== 'X' || filter.unit !== '' || (text ? text !== '' : filter.guestName !== '')) {
            search.length > 0 ? search += '&filter=' : search = '?filter=';
            slice = true;
        }
        if (filter.type !== 'X') {
            search += `type:eq:${filter.type}|`;
        }
        if (filter.unit !== '') {
            search += `unit:eq:${filter.unit}|`;
        }
        // if (filter.dateFrom !== '') {
        //     search += `dateFromBegin:gte:${moment(filter.dateFrom).format('YYYY-MM-DD')}|`;
        // }
        // if (filter.dateTo !== '') {
        //     search += `dateFromEnd:lte:${moment(filter.dateTo).format('YYYY-MM-DD')}|`;
        // }
        // if (filter.guestName !== '') {
        //     search += `guestName:rlike:${filter.guestName}|`;
        // }
        if ((text ? text !== '' : filter.guestName)) {
            search += `guestName:like:%` + (text ? text : filter.guestName) + '%|';
        }

        if (filter.building !== '') {
            search += `building:eq:${filter.building}|`
        }
        // if (filter.dateFrom !== '') {
        //     search += `date:eq:${moment(filter.dateFrom).format('YYYY-MM-DD')}|`
        // }

        if (slice === true) {
            search = search.slice(0, -1);
        }

        return this.http.get(fuseConfig.url + '/security/events' + search, {headers: headers, withCredentials: true});
    }

    getEventCounter(form, text): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        var headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        headers = headers.append('Login-Origin', 'web');
        // console.log(form);
        const filter = {... form};
        let search = '';
        let slice = false;
        if (filter.dateFrom !== '' || filter.dateTo !== '') {
            search = `?dateFrom=${moment(filter.dateFrom).format('YYYY-MM-DD')}&dateTo=${moment(filter.dateTo).format('YYYY-MM-DD')}`;
        }
        
        if (filter.status !== '') {
            search.length > 0 ? search += '&status=' : search = '?filter=';
            search += filter.status;
        }
        if (filter.type !== 'X' || filter.unit !== '' || (text ? text !== '' : filter.guestName !== '')) {
            search.length > 0 ? search += '&filter=' : search = '?filter=';
            slice = true;
        }
        if (filter.type !== 'X') {
            search += `type:eq:${filter.type}|`;
        }
        if (filter.unit !== '') {
            search += `unit:eq:${filter.unit}|`;
        }
        // if (filter.dateFrom !== '') {
        //     search += `dateFromBegin:gte:${moment(filter.dateFrom).format('YYYY-MM-DD')}|`;
        // }
        // if (filter.dateTo !== '') {
        //     search += `dateFromEnd:lte:${moment(filter.dateTo).format('YYYY-MM-DD')}|`;
        // }
        // if (filter.guestName !== '') {
        //     search += `guestName:rlike:${filter.guestName}|`;
        // }
        if ((text ? text !== '' : filter.guestName)) {
            search += `guestName:like:%` + (text ? text : filter.guestName) + '%|';
        }

        if (filter.building !== '') {
            search += `building:eq:${filter.building}|`
        }
        // if (filter.dateFrom !== '') {
        //     search += `date:eq:${moment(filter.dateFrom).format('YYYY-MM-DD')}|`
        // }

        if (slice === true) {
            search = search.slice(0, -1);
        }

        return this.http.get(fuseConfig.url + '/security/events/counter' + search, {headers: headers, withCredentials: true});
    }

    getGroupInvitationGuests(id): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/events/' + id + '/guests', {headers: headers, withCredentials: true});
    }

    getGuestLicensePhoto(url): Observable<any>{
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        var headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + url, {headers: headers, withCredentials: true});
    }

    getNotificationRules(unit, removeCode): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get( fuseConfig.url + '/security/notification_rules/' + unit + `?removeCode=${removeCode}`, {headers: headers, withCredentials: true});
    }

    getResidentQRDetails(building, qr): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(fuseConfig.url + '/security/residentQR/' + building + '/' + qr, {headers: headers, withCredentials: true});
    }

    getVehicleReport(building, filter): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        let filterValues = `?filter=unit:like:${filter.unit}`;

        if (filter.guest) {
            filterValues += `|guest:like:${filter.guest}`;
        }

        if (filter.plate) {
            filterValues += `|plate:like:${filter.plate}`;
        }

        // if (filter.tag) {
        //     filterValues += `|tag:like:${filter.tag}`;
        // }

        if (filter.cardCodeFrom && filter.cardCodeTo) {
            filterValues += `|cardCode:r:${filter.cardCodeFrom}:${filter.cardCodeTo}`;
        } else if (filter.cardCodeFrom) {
            filterValues += `|cardCode:gte:${filter.cardCodeFrom}`;
        } else if (filter.cardCodeTo) {
            filterValues += `|cardCode:lte:${filter.cardCodeTo}`;
        }

        if (filter.cardFacility) {
            filterValues += `|facilityCode:eq:${filter.cardFacility}`;
        }
        
        if (filter.brand) {
            filterValues += `|brand:like:${filter.brand}`;
        }
        if (filter.model) {
            filterValues += `|model:like:${filter.model}`;
        }
        if (filter.color) {
            filterValues += `|color:like:${filter.color}`;
        }
        if (filter.year) {
            filterValues += `|year:eq:${filter.year}`;
        }
        
        filter.issDateFrom = filter.issDateFrom ? moment(filter.issDateFrom).format('YYYY-MM-DD') : undefined;
        filter.issDateTo = filter.issDateTo ? moment(filter.issDateTo).format('YYYY-MM-DD') : undefined;
        
        if (filter.issDateFrom && filter.issDateTo) {
            filterValues += `|issDate:r:${filter.issDateFrom}:${filter.issDateTo}`;
        } else if (filter.issDateFrom) {
            filterValues += `|issDate:gte:${filter.issDateFrom}`;
        } else if (filter.issDateTo) {
            filterValues += `|issDate:lte:${filter.issDateTo}`;
        }

        // if (filter.resident !== '') {
        //     filter += `|resident:in:${filter.resident}`;
        // }
        return this.http.get(fuseConfig.url + '/security/vehicleReport/' + building + filterValues + `&guest=${filter.guest}&resident=${filter.unit}&residentsOnly=${filter.residentsOnly}`, { headers: headers, withCredentials: true });
    }

    getVehicleReportNew(building, filter): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        let filterValues = `?filter=unit:like:${filter.unit}`;
        // console.log(filter);

        if (filter.guest) {
            filterValues += `|guest:like:${filter.guest}`;
        }

        if (filter.plate) {
            filterValues += `|plate:like:${filter.plate}`;
        }

        // if (filter.tag) {
        //     filterValues += `|tag:like:${filter.tag}`;
        // }

        if (filter.cardCodeFrom && filter.cardCodeTo) {
            filterValues += `|cardCode:r:${filter.cardCodeFrom}:${filter.cardCodeTo}`;
        } else if (filter.cardCodeFrom) {
            filterValues += `|cardCode:gte:${filter.cardCodeFrom}`;
        } else if (filter.cardCodeTo) {
            filterValues += `|cardCode:lte:${filter.cardCodeTo}`;
        }

        if (filter.brand) {
            filterValues += `|brand:like:${filter.brand}`;
        }
        if (filter.model) {
            filterValues += `|model:like:${filter.model}`;
        }
        if (filter.color) {
            filterValues += `|color:like:${filter.color}`;
        }
        if (filter.year) {
            filterValues += `|year:eq:${filter.year}`;
        }
        if (filter.cardFacility) {
            filterValues += `|cardFacility:eq:${filter.cardFacility}`;
        }
        if (filter.cardCode) {
            filterValues += `|cardCode:eq:${filter.cardCode}`;
        }
        if (filter.resident) {
            filterValues += `|residentUnit:like:${filter.resident}`;
        }
        if (filter.door) {
            filterValues += `|door:eq:${filter.door}`;
        }
        if (filter.action) {
            filterValues += `|cardCode:eq:${filter.action}`;
        }
        if (filter.guest_type) {
            filterValues += `|guestType:eq:${filter.guest_type}`;
        }

        const fromHour = moment(filter.fromHour, 'HH:mm');
        const toHour = moment(filter.toHour, 'HH:mm');
        filter.issDateFrom = filter.dateType != 'all' && filter.issDateFrom ? moment(filter.issDateFrom).set({hour: fromHour.get('hour'), minute: fromHour.get('minute')}).format('YYYY-MM-DDTHH:mm:ss') : undefined;
        filter.issDateTo = filter.dateType != 'all' && filter.issDateTo ? moment(filter.issDateTo).set({hour: toHour.get('hour'), minute: toHour.get('minute')}).format('YYYY-MM-DDTHH:mm:ss') : undefined;

        // if (filter.issDateFrom && filter.issDateTo) {
        //     filterValues += `|issDate:r:${filter.issDateFrom}:${filter.issDateTo}`;
        if (filter.issDateFrom) {
            filterValues += `|issDate:gte:${filter.issDateFrom}`;
        }
        if (filter.issDateTo) {
            filterValues += `|issDate:lte:${filter.issDateTo}`;
        }

        // if (filter.resident !== '') {
        //     filter += `|resident:in:${filter.resident}`;
        // }
        return this.http.get(fuseConfig.url + '/security/vehicleReport/' + building + filterValues + `&residentsOnly=${filter.residentsOnly}${filter.includeLogs ? '&includeLogs=true' : ''}`, { headers: headers, withCredentials: true });
    }

    getGuestPhoto(guestId: string): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.get(`${fuseConfig.url}/security/guests/photo/license/${guestId}`, { headers: headers, withCredentials: true });
    }

    postSecurityIncident(body): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.post( fuseConfig.url + `/security/${this.selectedBuilding}/incident`, body, {headers: headers, withCredentials: true});
    }
    
    setupVideocallWebSocket(): any {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');

        const wsClient = new faye.Client(`${fuseConfig.fayeUrl}/fayeWs`, {
            retry: 30
        });

        wsClient.addExtension({
            outgoing: (message: any, callback: any) => {
                message.ext = message.ext || {};
                message.ext.token = token;
                callback(message);
            }
        });

        return wsClient;
    }
    
    cancelVideoCall(kiosk: string): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders().set('authorization', token);
        headers.append('Content-Type', 'application/json');
        return this.http.post(
          `${fuseConfig.url}/videocall/virtualGuard/cancel/${kiosk}`,
          {},
          { headers: headers, withCredentials: true }
        );
    }
    
    answerVideoCall(gate: string): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders()
          .set('authorization', token)
          .append('Content-Type', 'application/json');
        return this.http.post(
          `${fuseConfig.url}/videocall/virtualGuard/answered/${gate}`,
          {},
          { headers: headers, withCredentials: true }
        );
    }

    checkIn(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders()
          .set('authorization', token)
          .append('Content-Type', 'application/json');
        return this.http.put(
          `${fuseConfig.url}/security/checkIn`,
          {},
          { headers: headers, withCredentials: true }
        );
    }
    
    checkOut(): Observable<any> {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
        const headers = new HttpHeaders()
          .set('authorization', token)
          .append('Content-Type', 'application/json');
        return this.http.put(
          `${fuseConfig.url}/security/checkOut`,
          {},
          { headers: headers, withCredentials: true }
        );
    }

    checkOutAfterSessionKill(user: string): Observable<any> {
        const token = localStorage.getItem("token")
            ? localStorage.getItem("token")
            : sessionStorage.getItem("token");
        const headers = new HttpHeaders()
            .set("authorization", token)
            .append("Content-Type", "application/json");
        return this.http.put(
            `${fuseConfig.url}/videocall/virtualGuard/checkOut`,
            { user },
            { headers: headers, withCredentials: true },
        );
    }

    get authToken() {
        return localStorage.getItem("token") || sessionStorage.getItem("token");
    }

    getImageUrl(img: string) {
        return `${fuseConfig.url}/shared/image?img=${img}&X-GT-Authorization-Token=${this.authToken}`;
    }

    getImageFromUrl(url: string) {
        const token = this.authToken;
        const headers = new HttpHeaders()
            .set("authorization", token)
            .append("Content-Type", "application/json");

        return this.http.get(url, {
            headers: headers,
            withCredentials: true,
            responseType: "blob",
        });
    }
}