import { FuseConfig } from '@fuse/types';
import { environment as env } from 'environments/environment';

// Enums for managing multiple environments
enum ApiUrlEnvironments {
    dev = 'https://devapicloud.goutru.com:3000/api/v1',
    uat = 'https://uatapicloud.goutru.com:3000/api/v1',
    prod = 'https://apicloud.goutru.com:3000/api/v1'
}

enum FayeUrlEnvironments {
    dev = 'https://dev.goutru.com:8002',
    uat = 'https://uat.goutru.com:8002',
    prod = 'https://app.goutru.com:8002'
}

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme      : 'theme-default',
    customScrollbars: true,
    layout          : {
        style    : 'vertical-layout-1',
        width    : 'fullwidth',
        navbar   : {
            primaryBackground  : 'fuse-goutru-700',
            secondaryBackground: 'fuse-goutru-900',
            folded             : false,
            hidden             : false,
            position           : 'left',
            variant            : 'vertical-style-1'
        },
        toolbar  : {
            customBackgroundColor: false,
            background           : 'fuse-white-500',
            hidden               : false,
            position             : 'below-fixed'
        },
        footer   : {
            customBackgroundColor: true,
            background           : 'fuse-goutru-900',
            hidden               : false,
            position             : 'below-fixed'
        },
        sidepanel: {
            hidden  : false,
            position: 'right'
        }
    },
    url : '/app/api/v1',
    // url : 'http://localhost:3000/api/v1',
    url2: ApiUrlEnvironments[env.mode],
    scannerUrl: 'ws://localhost:8080/',
    fayeUrl: FayeUrlEnvironments[env.mode],
    captchaUrl: 'https://captcha.goutru.com/',
    passURL: '',
    App_Authorization: '',
    App_Version: 'v1.0.0',
    captchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    isOwner: false,
    isSecurity: false,
    isTowerAdmin: false,
    isBuildingAdmin: false,
    isGoutruAdmin: false,
    isDealer: false,
    isDealerTech: false,
    listDemo: false,
    user: {
        name: 'Loading...',
        lastname: '',
        email: '',
        phone: '',
        photo: null
    },
    kwikset: {
        loginUrl: 'https://auth.goconcourse.com/login?client_id=v39849ba95qrlkq2uk68lmqbe&response_type=code&scope=openid&redirect_uri=https%3A%2F%2Fdev.goutru.com%2Fsmartlocks%2Fsettings%2Fks%2Fredirect',
    }
};
